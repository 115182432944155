<template>
  <div>
    <b-row class="mb-2">
      <b-col md="9">
        <users-table-filters @filtersChanged="filtersChanged" />
      </b-col>
      <b-col md="3">
        <card-count-users :count="totalRows" />
      </b-col>
    </b-row>

    <b-card>
      <b-row class="pb-1">
        <b-col md="3" cols="6">
          <b-form-input v-model="searchValue" placeholder="Buscar" debounce="400" />
        </b-col>
        <b-col md="9" cols="6" class="text-right">
          <!-- <b-button variant="primary" class="px-1" @click="create()">
          <feather-icon size="1.2x" icon="PlusIcon" />
        </b-button> -->
        </b-col>
      </b-row>
      <b-table hover responsive="sm" :items="items" :fields="fields" :per-page="perPage" :busy="loading" show-empty>
        <template #cell(full_name)="data">
          <user-badge :user="data.item" :showPhone="true" />
        </template>

        <template #cell(residence_country)="data">
          <country-flag-icon :country="data.item.residence_country" />
        </template>

        <template #cell(account_type)="data">
          <span class="text-capitalize">{{
              data.item.account_type === 'personal' ? 'Personal' : 'Empresa'
          }}</span>
        </template>

        <template #cell(verified)="data">
          <user-status-badge :user="data.item" />
        </template>

        <template #cell(balances)="data">
          <div :class="{'text-danger' : data.item.balances.balance < 0 }"><b>Disponible:</b> {{ numberFormat(data.item.balances.balance) }} USD</div>
          <div><b>Pendiente:</b> {{ numberFormat(data.item.balances.pending_balance) }} USD</div>
        </template>

        <template #cell(created_at)="data">
          <span>{{ dateFormat(data.item.created_at) }}</span>
        </template>

        <template #cell(vip)="data">
          <user-change-vip-status :user="data.item" v-if="permissions_requerid.includes('activate-or-deactivate-vip-user')"/>
        </template>

        <template #cell(active)="data">
          <user-change-active-status :user="data.item" v-if="permissions_requerid.includes('activate-and-deactivate-user')"/>
        </template>


        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <!-- <user-change-active-status :user="data.item" /> -->
            <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" />
              </template>
              <b-dropdown-item @click="showDetail(data.item)">Ver detalle</b-dropdown-item>
              <b-dropdown-item @click="resetPin(data.item)">Resetear PIN</b-dropdown-item>
              <b-dropdown-item @click="deleteUser(data.item)">Eliminar</b-dropdown-item>
              <b-dropdown-item @click="editUser(data.item)">Editar</b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-5 pt-3">
            <b-spinner type="grow" class="align-middle"></b-spinner>
            <strong class="ml-1">Cargando</strong>
          </div>
        </template>

        <template #empty>
          <div class="text-center my-5 pt-3 d-flex w-100 justify-content-center align-items-center">
            <feather-icon icon="AlertCircleIcon" size="1.5x" />
            <strong class="ml-1">No hay registros para mostrar</strong>
          </div>
        </template>
      </b-table>
      <b-row v-show="items.length > 0 && !loading"><b-col md="2" sm="12" class="d-none d-sm-block">
          <b-form-group class="mb-0">
            <label class="d-inline-block mr-50">Por página</label>
            <b-form-select id="perPageSelect" :value="perPage" size="sm" :options="pageOptions" @change="perPageChange"
              style="width: auto" />
          </b-form-group>
        </b-col>
        <b-col v-show="totalRows > perPage" md="10" sm="12">
          <b-pagination striped hover responsive first-number last-number :value="currentPage" :total-rows="totalRows"
            :per-page="perPage" align="right" size="sm" class="my-0" @change="changePage" />
        </b-col>
      </b-row>

      <b-sidebar width="30%" v-model="open" bg-variant="white" right backdrop shadow>
        <!-- <AddUpdateClient :item="item" @saved="() => openSidebar(false)" /> -->
          <update-user-app :countryOptions="countryOptions" :item="item" @saved="saved" />
      </b-sidebar>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BCol,
  BPagination,
  BRow,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BButton,
  BSidebar,
  VBToggle,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

// import AddUpdateClient from './components/AddUpdateClient'

import { confirmAlert } from '@/helpers/utils'

import numberMixins from '@/mixins/numberMixins'
import UserBadge from '@/components/UserBadge'
import StatusTransationBadge from '@/components/StatusTransationBadge'
import CountryFlagIcon from '@/components/CountryFlagIcon'
import UserStatusBadge from '@/components/UserStatusBadge'
import UserChangeActiveStatus from '@/components/UserChangeActiveStatus'
import UsersTableFilters from '@/components/UsersTableFilters'
import CardCountUsers from '@/components/CardCountUsers'
import UserChangeVipStatus from '@/components/UserChangeVipStatus'
import { verifyPermission } from '@/router';
import UpdateUserApp from '@/components/UpdateUserApp';

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BCol,
    BPagination,
    BRow,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BSidebar,
    BSpinner,
    UserBadge,
    StatusTransationBadge,
    UserBadge,
    CountryFlagIcon,
    UserStatusBadge,
    BFormCheckbox,
    UserChangeActiveStatus,
    UsersTableFilters,
    CardCountUsers,
    UserChangeVipStatus,
    UpdateUserApp
  },
  mixins: [numberMixins],
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      open: false,
      searchValue: '',
      item: null,
      countryOptions: [],
      fields: [
        {
          key: 'full_name',
          label: 'Usuario',
          sortable: true,
        },
        {
          key: 'balances',
          label: 'Balance',
          sortable: true,
        },
        {
          key: 'residence_country',
          label: 'País',
          sortable: true,
        },
        {
          key: 'account_type',
          label: 'Tipo de cuenta',
          sortable: true,
        },
        {
          key: 'verified',
          label: 'Estatus',
          sortable: true,
        },
        {
          key: 'vip',
          label: 'VIP',
          sortable: true,
        },
        {
          key: 'active',
          label: 'Activo',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Acciones',
          sortable: false,
        },
      ],
      permissions_requerid: []
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.clients.items,
      perPage: (state) => state.clients.perPage,
      currentPage: (state) => state.clients.currentPage,
      totalRows: (state) => state.clients.totalRows,
      pageOptions: (state) => state.clients.pageOptions,
      searchTerm: (state) => state.clients.searchTerm,
      loading: (state) => state.clients.loading,
    }),
  },
  methods: {
    filtersChanged(filters) {
      this.$store.dispatch('clients/filtersChange', filters)
      this.getItems(1)
    },
    async getCountryOptions() {
      const res = await this.$store.dispatch('admins/getCountryOptions', {})
      if (res.data != undefined && res.data.length > 0) {

        this.countryOptions = res.data.map((item) => {
          return {
            value: item.iso_code,
            label: item.name,
          }
        })
      }
    },
    editUser(item) {
      this.item = item
      this.openSidebar()
    },
    saved() {
      this.item = null
      this.openSidebar(false)
    },
    showDetail(item) {
      this.$router.push({ name: 'client-detail', params: { id: item.id } })
    },
    async resetPin(item) {
      const res = await confirmAlert('¿Está seguro de resetear el PIN del usuario?')
      if (res.value) {
        const r = await this.$store.dispatch('clients/resetPin', {
          id: item.id,
        })
        this.$swal('Pin Reseteado', 'El nuevo pin se envio al correo del usuario', 'success')
      }
    },
    async deleteUser(item) {
      console.log(item.id)
      const res = await confirmAlert('¿Está seguro de eliminar este usuario?')
      if (res.value) {
        const r = await this.$store.dispatch('clients/deleteUser', {
          id: item.id,
        })
        if(r.success) {
          this.$swal('Usuario eliminado', 'El usuario se elimino correctamente', 'success')
          this.getItems()
        } else {
          this.$swal('Error', 'El usuario no se pudo eliminar', 'error')
        }
      }
    },
    openSidebar(open = true) {
      this.open = open
    },
    getItems(page = 1, filters = {}) {
      this.$store.dispatch('clients/get', {
        currentPage: page,
        filters,
      })
    },
    changePage(page) {
      this.getItems(page)
    },
    perPageChange(perPage) {
      this.$store.dispatch('clients/perPageChange', perPage)
      this.getItems()
    },
    search(value) {},
  },
  mounted() {
    console.log('mounted')
    this.$store.dispatch('clients/searchTermChange', '')
    this.getItems()
    this.getCountryOptions()
    let change_status_user = verifyPermission({ namePerm: 'activate-and-deactivate-user' })
    let change_vip_user = verifyPermission({ namePerm: 'activate-or-deactivate-vip-user' })
    let permissions_requerid_verify = []
    if (change_status_user) {
      permissions_requerid_verify.push("activate-and-deactivate-user")
    } else {
      this.fields.splice(6, 1)
    }

    if (change_vip_user) {
      permissions_requerid_verify.push("activate-or-deactivate-vip-user")
    } else {
      this.fields.splice(5, 1)
    }

    this.permissions_requerid = permissions_requerid_verify
  },
  watch: {
    searchValue(value) {
      this.$store.dispatch('clients/searchTermChange', value)
      this.getItems()
    },
  },
}
</script>

<style>
.badge-primary {
  background-color: #94a0ff;
}
</style>
