<template>
  <div>
    <validation-observer ref="updateUserApp">
      <b-form class="mt-2" @submit.prevent="updateUser">
        <b-container>
          <b-row>
            <b-col>
              <b-form-group label="Primer Nombre" label-for="userData-first-name">
                <validation-provider
                  #default="{ errors }"
                  name="Primer Nombre"
                  rules="required"
                >
                  <b-form-input
                    id="userData-first-name"
                    v-model="userData.first_name"
                    :state="errors.length > 0 ? false : null"
                    name="first_name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Segundo Nombre" label-for="userData-last-name">
                <validation-provider
                  #default="{ errors }"
                  name="Segundo Nombre"
                >
                  <b-form-input
                    id="userData-last-name"
                    v-model="userData.last_name"
                    :state="errors.length > 0 ? false : null"
                    name="last_name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Primer Apellido" label-for="userData-first-surname">
                <validation-provider
                  #default="{ errors }"
                  name="Primer Apellido"
                >
                  <b-form-input
                    id="userData-first-surname"
                    v-model="userData.first_surname"
                    :state="errors.length > 0 ? false : null"
                    name="first_surname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Segundo Apellido" label-for="userData-last-surname">
                <validation-provider
                  #default="{ errors }"
                  name="Segundo Apellido"
                >
                  <b-form-input
                    id="userData-last-surname"
                    v-model="userData.last_surname"
                    :state="errors.length > 0 ? false : null"
                    name="last_surname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Email" label-for="userData-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="userData-email"
                    v-model="userData.email"
                    :state="errors.length > 0 ? false : null"
                    name="userData-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Teléfono" label-for="userData-phone">
                <validation-provider
                  #default="{ errors }"
                  name="Teléfono"
                  rules="required"
                >
                  <b-form-input
                    id="userData-phone"
                    v-model="userData.phone"
                    :state="errors.length > 0 ? false : null"
                    name="userData-phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider #default="{ errors }" name="Tipo de identificacion" rules="required">
                <b-form-group label="Tipo de identificacion" label-for="user-identification-type"
                  :state="errors.length > 0 ? false : null">
                  <v-select v-model="userData.identification_type" :options="identificationTypeOptions" :reduce="val => val.value"
                    :clearable="false" input-id="user-identification-type" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <b-form-group label="Numero identificacion" label-for="userData-identification-number">
                <validation-provider
                  #default="{ errors }"
                  name="Numero identificacion"
                  rules="required"
                >
                  <b-form-input
                    id="userData-identification-number"
                    v-model="userData.identification_number"
                    :state="errors.length > 0 ? false : null"
                    name="userData-identification-number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <validation-provider #default="{ errors }" name="Rol del usuario" rules="required">
                <b-form-group label="Rol del usuario" label-for="user-role" :state="errors.length > 0 ? false : null">
                  <v-select v-model="userData.role" :options="roleOptions" :reduce="val => val.value" :clearable="false"
                    input-id="user-role" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider> -->

              <validation-provider #default="{ errors }" name="País de nacimiento" rules="required">
                <b-form-group label="País de nacimiento" label-for="user-country_of_birth"
                  :state="errors.length > 0 ? false : null">
                  <v-select v-model="userData.country_of_birth" :options="countryOptions" :reduce="val => val.value"
                    :clearable="false" input-id="user-country-of-birth" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider #default="{ errors }" name="País de residencia" rules="required">
                <b-form-group label="País de residencia" label-for="user-country_of_residence"
                  :state="errors.length > 0 ? false : null">
                  <v-select v-model="userData.country_of_residence" :options="countryOptions" :reduce="val => val.value"
                    :clearable="false" input-id="user-country-of-residence" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- <b-form-group label="Contraseña" label-for="userData-password">
                <validation-provider #default="{ errors }" name="Contraseña" :rules="!userData.id ? 'required' : ''">
                  <div role="group" class="input-group mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="userData-password" v-model="userData.password"
                      :state="errors.length > 0 ? false : null" name="password"
                      :type="showPassword ? 'text' : 'password'" />

                    <div class="input-group-append" click="showPassword = !showPassword">
                      <div class="input-group-text">
                        <b-button variant="default" class="p-0" @click="updateIconPass()">
                          <feather-icon :icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'" class="m-0 red" />
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div
                class="d-flex justify-content-center align-items-center py-5"
              >
                <b-button type="submit" variant="primary">Guardar</b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BCard,
  VBToggle,
  BFormCheckbox,
  BFormCheckboxGroup,
  BCardHeader,
  BCardText,
  BCollapse,
  BCardBody,
  BAvatar
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
// import formValidation from '@core/comp-functions/forms/form-validation'

const userDataInitialData = {
  id: null,
  first_name: null,
  last_name: null,
  first_surname: null,
  last_surname: null,
  phone: null,
  email: null,
  identification_type_id: null,
  identification_number: null,
  country_of_birth: null,
  country_of_residence: null
}

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    vSelect,
    BCard,
    FeatherIcon,
    BFormCheckbox,
    BFormCheckboxGroup,
    BCardHeader,
    BCardText,
    BCollapse,
    BCardBody,
    BAvatar
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    // identificationTypeOptions: {
    //   type: Array,
    //   default: () => [],
    // },
    countryOptions: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      userData: Object.assign({}, userDataInitialData),
      showPassword: false,
      identificationTypeOptions: [],
    }
  },
  computed: {
    ...mapState({
      platformBankAccounts: (state) =>
        state.app.authInitData.platform_bank_accounts,
      platformTransactions: (state) =>
        state.app.initData.platform_transaction_types,
    }),
  },
  methods: {
    changeCountryOfBirth(d) {
      console.log(this.userData.country_of_birth, d)
      this.getIdentificationTypeOptions(this.userData.country_of_birth)
    },
    async getIdentificationTypeOptions(countryCode) {
      const res = await this.$store.dispatch('admins/getIdentificationTypeOptions', {
        countryCode: countryCode,
      })
      if (res.data != undefined && res.data.length > 0) {

        this.identificationTypeOptions = res.data.map((item) => {
          return {
            id: item.id,
            value: item.id,
            label: item.name,
          }
        })

      }
      return this.identificationTypeOptions
    },
    // compare two arrays and return the number of elements that are the same
    compareArrays(arr1, arr2) {
      const acs = arr1.map(a => a.id)
      return acs.filter((val) => arr2.includes(val)).length
    },
    // check if all the elements of an array are in another array
    isSubset(arr1, arr2) {
      const acs = arr1.map(a => a.id)
      return acs.every((val) => arr2.includes(val))
    },
    checkAll(accounts) {
      const acs = accounts.map(a => a.id)
      if (this.isSubset(accounts, this.userData.manage_accounts)) {
        this.userData.manage_accounts = this.userData.manage_accounts.filter(
          (a) => !acs.includes(a)
        )
      } else {
        this.userData.manage_accounts = [...new Set(this.userData.manage_accounts.concat(acs))]
      }
    },
    async getPlatformBaknAccount() {
      await this.$store.dispatch('app/getPlatformBankAccounts', {})
    },
    updateIconPass() {
      this.showPassword = !this.showPassword
    },
    updateUser() {
      console.log(
        'userData',
        this.userData
      )

      const updateData = {
        id: this.userData.id,
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        first_surname: this.userData.first_surname,
        last_surname: this.userData.last_surname,
        phone: this.userData.phone,
        email: this.userData.email,
        identification_type_id: this.userData.identification_type,
        identification_number: this.userData.identification_number,
        country_of_birth: this.userData.country_of_birth,
        country_of_residence: this.userData.country_of_residence
      }

      console.log(updateData)
      this.$refs.updateUserApp.validate().then(async (success) => {
        if (success) {
          const res = await this.$store.dispatch('clients/updateUserApp', updateData)
          this.userData = Object.assign({}, userDataInitialData)
          this.$refs.updateUserApp.reset()
          this.$emit('saved', res)

          if (res.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuario actualizado correctamente',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.$store.dispatch('admins/get', {
              currentPage: 1,
            })
          } else {
            this.$swal('Error', 'Hubo un error al guardar el usuario', 'error')
          }
        }
      })
    },
  },
  watch: {
    // userData.country_of_birth watch
    'userData.country_of_birth': async function (val) {
      if (val) {
        const ident = await this.getIdentificationTypeOptions(val)
        this.userData.identification_type = null
        this.identificationTypeOptions = ident
      }
    },
    item: {
      handler: async function (val) {
        if (val) {
          this.userData = Object.assign({}, val)
          const ident = await this.getIdentificationTypeOptions(val.country_of_birth)

          if (val.country_of_birth != undefined) {
            let countrySelect = this.countryOptions.find((itemFind) => itemFind.value == val.country_of_birth)
            if (countrySelect != undefined) {
              this.userData.country_of_birth = countrySelect.value
            }
          }

          if (val.country_of_residence != undefined) {
            let countrySelect = this.countryOptions.find((itemFind) => itemFind.value == val.country_of_residence)
            if (countrySelect != undefined) {
              this.userData.country_of_residence = countrySelect.value
            }
          }

          if (val.identification_type_id != undefined) {
            console.log(ident)
            let identificationType = ident.find(
              (itemFind) => itemFind.id == val.identification_type_id
            )
            console.log(identificationType.value)
            if (identificationType != undefined) {
              this.userData.identification_type = identificationType.value
            }

            // this.userData.manage_accounts = val.manage_accounts ?? []
            // this.userData.manage_transactions = val.manage_transactions ?? []
          }

        } else {
          this.userData = Object.assign({}, userDataInitialData)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getPlatformBaknAccount()
  },
}
</script>
