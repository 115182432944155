var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"updateUserApp"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updateUser($event)}}},[_c('b-container',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Primer Nombre","label-for":"userData-first-name"}},[_c('validation-provider',{attrs:{"name":"Primer Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"userData-first-name","state":errors.length > 0 ? false : null,"name":"first_name"},model:{value:(_vm.userData.first_name),callback:function ($$v) {_vm.$set(_vm.userData, "first_name", $$v)},expression:"userData.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Segundo Nombre","label-for":"userData-last-name"}},[_c('validation-provider',{attrs:{"name":"Segundo Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"userData-last-name","state":errors.length > 0 ? false : null,"name":"last_name"},model:{value:(_vm.userData.last_name),callback:function ($$v) {_vm.$set(_vm.userData, "last_name", $$v)},expression:"userData.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Primer Apellido","label-for":"userData-first-surname"}},[_c('validation-provider',{attrs:{"name":"Primer Apellido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"userData-first-surname","state":errors.length > 0 ? false : null,"name":"first_surname"},model:{value:(_vm.userData.first_surname),callback:function ($$v) {_vm.$set(_vm.userData, "first_surname", $$v)},expression:"userData.first_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Segundo Apellido","label-for":"userData-last-surname"}},[_c('validation-provider',{attrs:{"name":"Segundo Apellido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"userData-last-surname","state":errors.length > 0 ? false : null,"name":"last_surname"},model:{value:(_vm.userData.last_surname),callback:function ($$v) {_vm.$set(_vm.userData, "last_surname", $$v)},expression:"userData.last_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"userData-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"userData-email","state":errors.length > 0 ? false : null,"name":"userData-email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"userData-phone"}},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"userData-phone","state":errors.length > 0 ? false : null,"name":"userData-phone"},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Tipo de identificacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de identificacion","label-for":"user-identification-type","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.identificationTypeOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-identification-type"},model:{value:(_vm.userData.identification_type),callback:function ($$v) {_vm.$set(_vm.userData, "identification_type", $$v)},expression:"userData.identification_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Numero identificacion","label-for":"userData-identification-number"}},[_c('validation-provider',{attrs:{"name":"Numero identificacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"userData-identification-number","state":errors.length > 0 ? false : null,"name":"userData-identification-number"},model:{value:(_vm.userData.identification_number),callback:function ($$v) {_vm.$set(_vm.userData, "identification_number", $$v)},expression:"userData.identification_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"País de nacimiento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"País de nacimiento","label-for":"user-country_of_birth","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.countryOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-country-of-birth"},model:{value:(_vm.userData.country_of_birth),callback:function ($$v) {_vm.$set(_vm.userData, "country_of_birth", $$v)},expression:"userData.country_of_birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"País de residencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"País de residencia","label-for":"user-country_of_residence","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.countryOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-country-of-residence"},model:{value:(_vm.userData.country_of_residence),callback:function ($$v) {_vm.$set(_vm.userData, "country_of_residence", $$v)},expression:"userData.country_of_residence"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-center align-items-center py-5"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Guardar")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }